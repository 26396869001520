import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_LOCATION, LOCATION_TEST, DELETE_LOCATION, GET_LOCATIONS, GET_SPECIFIC_LOCATION, UPDATE_LOCATION } from "./actionTypes"
import { getLocationSuccess, getLocationFail, updateLocationLoading1, updateLocationSuccess, deleteLocationSuccess, selectLocation } from "./actions"
import service from "../../helpers/Api/FetchInterceptor"
import { getSavedLocations } from "../../helpers/backend_helper"
import { useHistory } from "react-router-dom"
// import { push } from 'connected-react-router';
import { push } from 'react-router-redux';

//Include Both Helper File with needed methods
function getLocations(params) {
  return async function () {
    let result = await service.get('/locations' + "?" + params);
    return result.data;
  }
}
function getLocation(id) {
  return async function () {
    let result = await service.get('/locations/' + id);
    return result.data;
  }
}

function putLocation(location) {
  return async function () {
    const formData = new FormData();
    Object.keys(location).forEach(key => formData.append(key, object[key]));
    let result = await service.put('/locations', {
      data: formData
    });
    return result.data;
  }
}

function* fetchLocation({
  payload: {payload, history}
}) {
  try {
    console.log(payload, history, "XXXXXXXXXXXXXXXXXXXXXXXXXXXXX==============================>")
    const response = yield call(() => getSavedLocations())
    yield put(getLocationSuccess(response))
    if(response.length) {
      yield put(selectLocation(response[0].id));
      // history?.push("/"+response[1].id+"/locations")
    }
  } catch (error) {
    yield put(getLocationFail(error))
  }
}

function* addLocation({
  payload
}) {
  try {
    yield put(updateLocationLoading1(true))
    const response = yield call(() => payload)
    yield put(updateLocationSuccess(response.data.location))
  } catch (error) {
    yield put(getLocationFail(error))
  } finally {
    yield put(updateLocationLoading1(false))
  }
}

function* updateLocation({
  payload
}) {
  try {
    yield put(updateLocationLoading1(true))
    const response = yield call(() => payload)
    yield put(updateLocationSuccess(response.data.location))
  } catch (error) {
    yield put(getLocationFail(error))
  } finally {
    yield put(updateLocationLoading1(false))
  }
}

function* fetchLocationWithId({
  payload
}) {
  try {
    yield put(updateLocationLoading1(true))
    const response = yield call(() => payload)
    yield put(updateLocationSuccess(response.data.location))
  } catch (error) {
    yield put(getLocationFail(error))
  } finally {
    yield put(updateLocationLoading1(false))
  }
}

function* deleteLocation({
  payload
}) {
  try {
    yield put(updateLocationLoading1(true))
    const response = yield call(() => payload)
    console.log('called', response)
    yield put(deleteLocationSuccess(response.data.location))
  } catch (error) {
    yield put(getLocationFail(error))
  } finally {
    yield put(updateLocationLoading1(false))
  }
}

function* fetchTest({
  payload
}) {
  try {
    yield put(updateLocationLoading1(true))
    const response = yield call(() => payload)
  } catch (error) {
    yield put(getLocationFail(error))
  } finally {
    yield put(updateLocationLoading1(false))
  }
}

function* locationsSaga() {
  yield takeEvery(GET_LOCATIONS, fetchLocation)
  yield takeEvery(ADD_LOCATION, addLocation)
  yield takeEvery(UPDATE_LOCATION, updateLocation)
  yield takeEvery(GET_SPECIFIC_LOCATION, fetchLocationWithId)
  yield takeEvery(DELETE_LOCATION, deleteLocation)
  yield takeEvery(LOCATION_TEST, fetchTest)
}

export default locationsSaga
