import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from "yup";
import Switch from "react-switch"
import { addUser, getUsers, getSpecificUser, getTest, updateUser } from '../../store/actions'
import { Checkbox, message, Popconfirm, Spin } from 'antd'
import { fetchAllLocations } from 'components/CommonForBoth/TopbarDropdown/HeaderLocations';
import { allocateUserToLocation, deallocateUserToLocation } from 'helpers/backend_helper';

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Yes
    </div>
  )
}

export class UserOperations extends Component {
  static propTypes = {
    type: PropTypes.any
  }


  constructor(props) {
    super(props)
    this.state = {
      selectedFiles: [],
      isSubmiting: false,
      fetchLocations: [],
      isFechLocationsLoading: true,
      isLoadingOperationOfAssignLocation: false,
    }
    this.handleAllowLoacation = this.handleAllowLoacation.bind(this);
  }


  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  async componentDidMount() {
    const { match } = this.props;
    // this.props.getTest().payload.then(res => message.success("API CALLED"))
    if (this.props.type == "EDIT") {
      console.log('Getting ID', match.params.userId)
      this.props.getSpecificUser(match.params.userId)
      // const data = await fetchAllLocations();
      // this.setState({
      //   fetchLocations: data,
      //   isFechLocationsLoading: false
      // })
    }
  }


  async handleAllowLoacation(i) {
    // return function () {
    const that = this;
    if (this.props.user?.user_location?.find(j => i.id == j.saved_location_id) ? true : false) {
      that.setState({
        isLoadingOperationOfAssignLocation: true
      })
      const deleteSaveLocationId = this.props.user?.user_location?.find(j => i.id == j.saved_location_id).id;
      deallocateUserToLocation(deleteSaveLocationId).finally(() => {
        that.setState({
          isLoadingOperationOfAssignLocation: false
        })
        that.props.user.user_location = that.props.user.user_location.filter(loc => loc.saved_location_id != i.id)
      })

    } else {
      that.setState({
        isLoadingOperationOfAssignLocation: true
      })
      allocateUserToLocation(this.props.user.id, i.id).finally(() => {
        that.setState({
          isLoadingOperationOfAssignLocation: false
        })
        that.props.user.user_location.push({ ...i, saved_location_id: i.id })
      })
    }
    // }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content mt-4">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Users" breadcrumbItem={`${this.props.type == "ADD" ? 'Add' : 'Update'} User`} />

            <Row>
              <Col xs="12">
                <Spin spinning={this.props.userLoading1}>
                  <Card>
                    <CardHeader>Basic Information</CardHeader>
                    <CardBody>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          ...(this.props.type == "EDIT" ? this.props.user : {})
                        }}
                        validationSchema={
                          Yup.object().shape({
                            name: Yup.string().required(),
                            email: Yup.string().email().required(),
                            phone: Yup.number().required(),
                            password:
                              (this.props.type == "EDIT" ? Yup.string().nullable() : Yup.string().required(
                                "Please Enter Password"
                              ))
                            ,
                          })}
                        onSubmit={values => {
                          const that = this;
                          this.setState({ isSubmiting: true })
                          values.is_active = values.is_active ? 1 : 0;
                          let apiSubmit = null;
                          if (this.props.type == "EDIT") {
                            if (!(values.image instanceof File)) {
                              delete values['image']
                            }
                            values._method = 'patch'
                            apiSubmit = this.props.updateUser(this.props.match.params.userId, values)
                          } else {
                            apiSubmit = this.props.addUser(values)
                          }
                          console.log(apiSubmit)
                          apiSubmit
                            .payload
                            .then(res => {
                              message.success(res.message);
                              that.props.history.push("/users");
                            })
                            .catch(err => message.error('Something wrong'))
                            .finally(() => {
                              console.log(that.state)
                              that.setState({ 'isSubmiting': false });
                            })
                        }}
                      >
                        {({ errors, status, touched, setFieldValue, validateForm, values, handleSubmit }) => (
                          <Form className="form-horizontal">
                            <Row>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label htmlFor="productname">User Name *</Label>
                                  <Field
                                    name="name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.name
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>

                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="email" className="form-label">
                                    Email *
                                  </Label>
                                  <Field
                                    name="email"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="password" className="form-label">
                                    Password *
                                  </Label>
                                  <Field
                                    name="password"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="phone" className="form-label">
                                    Phone *
                                  </Label>
                                  <Field
                                    name="phone"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.phone
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="phone"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label className='pr-3'>User Status</Label> <br />
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      checkedIcon={<OnSymbol />}
                                      onColor="#626ed4"
                                      onChange={() =>
                                        setFieldValue('is_active', values.is_active ? !true : !false)
                                      }
                                      checked={values.is_active ? true : false}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Row>
                            <div className="d-flex flex-wrap gap-2 mt-4">
                              <Popconfirm
                                title="Update User Information"
                                style={{
                                  zIndex: 99999
                                }}
                                description="Are you sure about ?"
                                onConfirm={() => {
                                  handleSubmit();
                                  return true
                                }}
                              // onOpenChange={() => console.log('open change')}
                              >
                                <Button
                                  // type="submit"
                                  color="primary"
                                  disabled={this.state.isSubmiting}
                                >

                                  {this.state.isSubmiting ? ' Saving....' : 'Save Changes'}
                                </Button>
                              </Popconfirm>
                            </div>
                          </Form>
                        )}
                      </Formik>

                    </CardBody>
                  </Card>
                  {this.props.type != "ADD" && (
                    <Card>
                      <CardHeader>Allowed Locations</CardHeader>
                      <Spin spinning={this.props.userLoading1 || this.state.isLoadingOperationOfAssignLocation} >
                        <CardBody>
                          {!this.props.userLoading1 && (
                            <>
                              {this.props.locations.map(i => (
                                <div key={i.id}>
                                  <Checkbox defaultChecked={this.props.user?.user_location?.find(j => i.id == j.saved_location_id) ? true : false} onClick={() => this.handleAllowLoacation(i)}>
                                    {i.title}
                                  </Checkbox>
                                </div>
                              ))}
                            </>
                          )}
                        </CardBody>
                      </Spin>
                    </Card>
                  )}
                </Spin>

              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  locations: state.Location.locations,
  user: state.User.single,
  userLoading1: state.User.loading1
})

const mapDispatchToProps = dispatch => ({
  getTest: (d) => dispatch(getTest(d)),
  addUser: payload => dispatch(addUser(payload)),
  updateUser: function () { return dispatch(updateUser(...arguments)) },
  getSpecificUser: payload => dispatch(getSpecificUser(payload))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserOperations))

