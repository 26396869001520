import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Button, Card, CardBody, Col, Container, Row, Label } from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import { updateUser } from "store/actions";
import { message } from "antd";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userObj: {},
      isSubmiting: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      this.setState({ userObj: obj });
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props !== prevProps) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      this.setState({ userObj: obj });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="GMB" breadcrumbItem="Profile" />

            <Row>
              <Col lg="12">
                {this.props.error && this.props.error ? (
                  <Alert color="danger">{this.props.error}</Alert>
                ) : null}
                {this.props.success && this.props.success ? (
                  <Alert color="success">{this.props.success}</Alert>
                ) : null}

                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          src={avatar}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                      <div className="align-self-center flex-1">
                        <div className="text-muted">
                          <h5>{this.state.userObj?.name}</h5>
                          <p className="mb-1">{this.state.userObj?.email}</p>
                          <p className="mb-0">Id no: #{this.state.userObj?.id}</p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h4 className="card-title mb-4">Change User Name</h4>

            <Card>
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    email: (this.state?.userObj.email) || "",
                    name: (this.state?.userObj.name) || "",
                    phone: (this.state?.userObj.phone) || "",
                    password: "",
                    id: (this.state?.userObj.id) || "",
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("Please Enter Your Username"),
                    phone: Yup.string().required("Please Enter Your Phone Number"),
                  })}
                  onSubmit={values => {
                    const that = this;
                    this.setState({ isSubmiting: true })
                    if (values.password == "") {
                      delete values.password
                    }
                    console.log(values)
                    let apiSubmit = null;
                    values._method = 'patch'
                    apiSubmit = this.props.updateUser(this.state.userObj.id, values)
                    console.log(apiSubmit)
                    apiSubmit
                      .payload
                      .then(res => {

                        message.success(res.message);
                        localStorage.setItem("authUser", JSON.stringify({ ...that.state.userObj, ...res.data.user }))
                        console.log(res.data, "RESPONSE")
                        // that.props.history.push("/");
                      })
                      .catch(err => message.error('Something wrong'))
                      .finally(() => {
                        console.log(that.state)
                        that.setState({ 'isSubmiting': false });
                      })
                  }}
                >
                  {({ errors, status, touched }) => (
                    <Form className="form-horizontal">
                      <div className="mb-3">
                        <Label for="name" className="form-label">
                          Username *
                        </Label>
                        <Field
                          name="name"
                          type="text"
                          className={
                            "form-control" +
                            (errors.name && touched.name
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-feedback"
                        />
                        <Field
                          name="name"
                          type="hidden"
                          className={
                            "form-control" +
                            (errors.name && touched.name
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <Label for="phone" className="form-label">
                          Phone *
                        </Label>
                        <Field
                          name="phone"
                          type="text"
                          className={
                            "form-control" +
                            (errors.phone && touched.phone
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="invalid-feedback"
                        />
                        <Field
                          name="phone"
                          type="hidden"
                          className={
                            "form-control" +
                            (errors.phone && touched.phone
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <Label for="password" className="form-label">
                          Password
                        </Label>
                        <Field
                          name="password"
                          type="text"
                          className={
                            "form-control" +
                            (errors.password && touched.password
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                        <Field
                          name="password"
                          type="hidden"
                          className={
                            "form-control" +
                            (errors.password && touched.password
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </div>
                      <div className="text-center mt-4">
                        <Button type="submit" color="danger" disabled={this.state.isSubmiting}>
                          {this.state.isSubmiting ? 'Updating....' : 'Update'}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
};

const mapStateToProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

const mapDispatchToProps = dispatch => ({
  updateUser: function () { return dispatch(updateUser(...arguments)) },
});


export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserProfile)
);
