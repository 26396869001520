import PropTypes from "prop-types";
import React, { Component } from "react";
import "react-drawer/lib/react-drawer.css";

import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

import { Link, withRouter } from "react-router-dom";
import { DownOutlined, UserOutlined, EditOutlined } from '@ant-design/icons';
// import { Button, Dropdown, message, Space, Tooltip } from 'antd';


// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import megamenuImg from "../../assets/images/megamenu-img.png";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

import logo from "../../assets/images/logo.svg";
import logoLightSvg from "../../assets/images/logo-light.svg";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import { toggleRightSidebar } from "../../store/actions";
import HeaderLocations from "../CommonForBoth/TopbarDropdown/HeaderLocations";
import { withId } from "../../helpers/helper";
import { Button } from "antd";
import AddPost from "../../pages/Locations/Component/AddPost";

const navigationSelected = function (name) {
  const PATH = this.props.history?.location?.pathname || "";
  const regExp = /\/(.*)\/(.*)/
  const regExp2 = new RegExp(`\/(.*)\/${name}(.*)`, "g");

  const match = PATH.match(regExp2);
  if (match) {
    return true
  }
  return false;
}
const navigationSelectedWithoutId = function (name) {
  const PATH = this.props.history?.location?.pathname || "";
  const regExp = /\/(.*)\/(.*)/
  const regExp2 = new RegExp(`\/${name}(.*)`, "g");

  const match = PATH.match(regExp2);
  if (match) {
    return true
  }
  return false;
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ID: this.props.match.params.id || 0,
      isSearch: false,
      open: false,
      newPostOpen: false,
      position: "right",
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.navigationSelected = navigationSelected.bind(this);
    this.navigationSelectedWithoutId = navigationSelectedWithoutId.bind(this);
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  setNewPostOpen(newPostOpen) {
    this.setState({ newPostOpen })
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    const USER = JSON.parse(localStorage.getItem("authUser"));
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box d-lg-none d-md-block">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="22" />
                  </span>
                </Link>
              </div>

              {/* <button
                type="button"
                onClick={this.toggleMenu}
                className="btn btn-sm px-3 font-size-16 header-item"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button> */}

              <HeaderLocations />


              {/* <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={this.props.t("Search") + "..."}
                  />
                  <span className="bx bx-search-alt"></span>
                </div>
              </form> */}

              <Dropdown
                className="dropdown-mega d-none d-lg-block ms-2"
                isOpen={this.state.megaMenuDrp}
                toggle={() => {
                  this.setState({ megaMenuDrp: !this.state.megaMenuDrp });
                }}
              >
                
                <Link to={withId("/dashboard", this.props?.selectedLocation?.id || 0)}>
                  <button className={`btn header-item ${(this.navigationSelected("dashboard") || this.navigationSelected("custom-report")) ? "selected" : ""}`}>
                    {this.props.t("Reports")}
                  </button>
                </Link>
                {USER.type == "admin" && (
                  <Link to={"/users"}>
                    <button className={`btn header-item ${(this.navigationSelectedWithoutId("users")) ? "selected" : ""}`}>
                      {this.props.t("Users")}
                    </button>
                  </Link>
                )}
                <Link to={withId("/reviews", this.props?.selectedLocation?.id || 0)}>
                  <button className={`btn header-item ${this.navigationSelected("reviews") ? "selected" : ""}`}>
                    {this.props.t("Reviews")}
                  </button>
                </Link>
                <Link to={withId("/posts", this.props?.selectedLocation?.id || 0)}>

                  <button className={`btn header-item ${this.navigationSelected("posts") ? "selected" : ""}`}>
                    {this.props.t("Posts")}
                  </button>
                </Link>
              </Dropdown>

            </div>
            <div className="d-flex">
              {
                !!this.state.ID && (
                  <>
                    <AddPost locationId={this.state.ID} isOpen={this.state.newPostOpen} setIsOpen={this.setNewPostOpen.bind(this)} />
                    <div className="d-flex align-items-center">
                      <Button className="cnt" type="primary" size="middle" icon={<EditOutlined />} shape="round" onClick={() => {
                        this.setNewPostOpen(true)
                      }}>
                        New Post
                      </Button>
                    </div>
                  </>
                )
              }
              <div className="dropdown d-none d-lg-inline-block ms-1">
                <button
                  type="button"
                  onClick={this.toggleFullscreen}
                  className="btn header-item noti-icon"
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen" style={{
                    color: "white"
                  }} ></i>
                </button>
              </div>

              {/* <NotificationDropdown /> */}
              <ProfileMenu />

              {/* <div className="dropdown d-inline-block">
                <button
                  onClick={() => {
                    this.toggleRightbar();
                  }}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle"
                >
                  <i className="bx bx-cog bx-spin"></i>
                </button>
              </div> */}
            </div>
          </div>
          {console.log(this.props.history.lov, "HISTORY")}
        </header>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  t: PropTypes.any,
  toggleMenuCallback: PropTypes.any,
  showRightSidebar: PropTypes.any,
  toggleRightSidebar: PropTypes.func,
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar } = state.Layout;
  const selectedLocation = state.Location.selectedLocation;
  return { layoutType, showRightSidebar, selectedLocation };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withRouter(withTranslation()(Header))
);
